@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");

/* processing screen */

.processing {
  animation-name: processAction;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  height: 100vh;
  width: 2px;

  z-index: 1000;
  /* margin-left: 30px;
    margin-top: 5px; */
}

@keyframes processAction {
  from {
    width: 0px;
  }
  15% {
    width: 100vw;
  }
  75% {
    width: 100vw;
  }
  to {
    width: 0px;
  }
}
