@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* processing screen */

.processing {
  -webkit-animation-name: processAction;
          animation-name: processAction;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  height: 100vh;
  width: 2px;

  z-index: 1000;
  /* margin-left: 30px;
    margin-top: 5px; */
}

@-webkit-keyframes processAction {
  from {
    width: 0px;
  }
  15% {
    width: 100vw;
  }
  75% {
    width: 100vw;
  }
  to {
    width: 0px;
  }
}

@keyframes processAction {
  from {
    width: 0px;
  }
  15% {
    width: 100vw;
  }
  75% {
    width: 100vw;
  }
  to {
    width: 0px;
  }
}

.floating {
  -webkit-animation-name: floatAction;
          animation-name: floatAction;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@-webkit-keyframes floatAction {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}

@keyframes floatAction {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, -0px);
  }
}

.technologyBlock {
  transition: all 0.5s ease;
  box-shadow: inset 0px 0px 150px black;
}
.moveImageOnHover {
  transition: background-position 2s ease;
}

.doThingsSlowly {
  transition: all 1s ease;
}

.moveOnHover {
  padding-left: 400;
}

