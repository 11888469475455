.technologyBlock {
  transition: all 0.5s ease;
  box-shadow: inset 0px 0px 150px black;
}
.moveImageOnHover {
  transition: background-position 2s ease;
}

.doThingsSlowly {
  transition: all 1s ease;
}

.moveOnHover {
  padding-left: 400;
}
